import { Controller } from 'stimulus'

export default class extends Controller
  @targets = []

  connect: ->
    @customerIO()

  customerIO: ->
    cioData = @data.get('customerio')
    unless typeof(_cio) == 'undefined' || cioData == null
      _cio.identify JSON.parse(cioData)
      _cio.page(
        window.location.href
        width: window.innerWidth,
        height: window.innerHeight,
        referrer: document.referrer
      ) if window.pageLoaded
