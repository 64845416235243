import { Controller } from 'stimulus'

export default class extends Controller
  @targets = ['continueButton']

  connect: ->
    if window.location.hash == '#open-summary'
      setTimeout ->
        CartSummary.toggle()
      , 1000

  toggle: (event) ->
    unless event.target == @continueButtonTarget
      CartSummary.toggle()

  resize: ->
    CartSummary.resize() if CartSummary.opened()
