Utilities =
  camelCase: (s) ->
    s.replace /([-_][a-z])/ig, ($1) ->
      $1.toUpperCase().replace('-', '').replace '_', ''

  camelToDash: (camelString) ->
    camelString.replace /[A-Z]/g, (m) ->
      '-' + m.toLowerCase()

export default Utilities
