import { Controller } from 'stimulus'

export default class ModalController extends Controller

  open: (event, coverage) ->
    $('.modal, .modal-root').attr('data-active', true)

  close: (event, callback) ->
    target = $(event.target)
    if event.type == 'click' && !target.closest("[data-action='modal#close']").length
      # don't try and close when clicking inside a modal
      return if target.closest('.modal').length
      # don't close when clicking an open button
      return if target.closest("[data-action='modal#open']").length
      return if window.currentTarget != event.target

    if event.type == 'keyup'
      return if event.key.toLowerCase() != 'escape'

    if typeof callback == 'function'
      callback()
    else
      $('.modal, .modal-root').attr('data-active', false)
