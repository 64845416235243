require './string'
import UAParser from "ua-parser-js"
import CartSummary from './cart_summary'
import CoverageModal from './coverage_modal'
import bugsnag from '@bugsnag/js'
import 'slick-carousel/slick/slick'

window.App ||= {}

window.CartSummary = CartSummary
window.CoverageModal = CoverageModal

App.DebugMode = eval("false")

# Disable console warnings unless development
unless App.DebugMode
  console.warn = ->

  window.bugsnagClient = bugsnag("bad1ea83e07639f618ed39c82324aca8")

App.log = (message) ->
  console.log("App: #{message}") if App.DebugMode

$window = $(window)
$document = $(document)

$document.on 'turbolinks:load', ->
  $html_body = $('html, body')
  $body = $('body')

  # Get browser
  ua = new UAParser
  browser = ua.getBrowser().name.toLowerCase()

  # Disable right clicking on Media Kit logos
  $('body.brand_asset_guide img').on 'contextmenu', (e) ->
    e.preventDefault()
    return false

  $body.attr('data-browser', browser)

  setTimeout ->
    $body.removeClass('preload')
  , 500

  $window.on 'resize', ->
    $body.addClass 'preload'
    clearTimeout resizeTimer
    resizeTimer = setTimeout ->
      $body.removeClass 'preload'
    , 500

  $('#how-it-works ul').slick
    arrows: false
    dots: false
    infinite: false
    slidesToShow: 3
    responsive: [
      {
        breakpoint: 850
        settings:
          dots: true
          slidesToShow: 1
          infinite: true
      }
    ]

  $('iframe').on 'load', =>
    $('iframe').closest('.skeleton-container').attr('data-loading', false)

# Prevent modal closing when dragging off
$window.on 'mousedown', (event) ->
  window.currentTarget = event.target
