import { Controller } from 'stimulus'

export default class extends Controller
  @targets = ['menu', 'btn']

  connect: ->


  toggleActive: () ->
    if $(@menuTarget).attr('data-active') != undefined
      $(@menuTarget).removeAttr('data-active')
    else
      $(@menuTarget).attr('data-active', '')

  collapse: ->
    $(@menuTarget).removeAttr('data-expanded') unless $(window).width() < 600

  expand: ->
    $(@menuTarget).attr('data-expanded', '') unless $(window).width() < 600

  removeActive: (event) ->
    return if $(event.target).closest(@menuTarget).length or $(event.target).closest(@btnTarget).length
    $(@menuTarget).removeAttr('data-active')
