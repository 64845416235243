import { Controller } from 'stimulus'

export default class extends Controller
  @targets = [
    'cardElement', 'paymentRequestButton', 'name', 'error'
  ]

  connect: ->
    window.checkoutController = @
    stripeKey = "pk_live_VES0uFKCJSQYdz5ZC01xVguP00hEpiaSag"
    @stripe = Stripe(stripeKey) if stripeKey

    @elements = @stripe.elements(
      fonts: [
        cssSrc: 'https://use.typekit.net/cdi1kno.css'
        name: 'futura-pt'
        weight: '600'
      ]
    )
    @createCardElement()
    @createPaymentRequestButton()

  disconnect: ->
    @cardElement.destroy()
    @paymentRequestButton.destroy()

  confirmPaymentIntent: (event) ->
    event.preventDefault() if event

    # this prevents the form from infinitely looping
    return if @paymentSuccess

    setTimeout =>
      $.rails.disableElement(@element)
    , 0

    paymentMethod = @paymentEvent.paymentMethod.id if @paymentEvent
    paymentMethod ||=
      {
        card: @cardElement
        billing_details:
          name: @nameTarget.value
          email: @data.get('email')
      }

    @stripe.confirmCardPayment(
      @data.get('paymentIntentClientSecret'),
      payment_method: paymentMethod
      setup_future_usage: 'off_session'
    ).then (result) =>
      if result.error
        setTimeout =>
          $.rails.enableElement(@element)
        , 250
        @setError result.error.message
        @paymentEvent.complete('success') if @paymentEvent
      else
        @paymentSuccess = true
        @element.submit()

  createCardElement: ->
    style =
      base:
        textTransform: 'capitalize'
        color: '#fff'
        fontFamily: 'futura-pt, sans-serif'
        fontSize: '19px'
        '::placeholder':
          color: 'rgb(102,102,102)'

    @cardElement = @elements.create('card', style: style)
    @cardElement.mount(@cardElementTarget)

  createPaymentRequestButton: ->
    paymentRequest = @stripe.paymentRequest(
      country: 'US'
      currency: 'usd'
      total:
        label: @data.get('prbLabel')
        amount: parseInt(@data.get('prbAmount'))
      displayItems: JSON.parse(@data.get('prbDisplayItems'))
      requestPayerName: true
      requestPayerEmail: true
      requestPayerPhone: true
    )
    @paymentRequestButton = @elements.create 'paymentRequestButton',
      paymentRequest: paymentRequest
      style:
        paymentRequestButton:
          type: 'default'
          theme: 'dark'
          height: '64px'

    paymentRequest.canMakePayment().then (result) =>
      if result
        @paymentRequestButton.mount @paymentRequestButtonTarget
        setTimeout =>
          $(@paymentRequestButtonTarget).slideDown()
        , 200

    paymentRequest.on 'paymentmethod', (event) =>
      $(@cardElementTarget).closest('.input').fadeOut()
      @paymentEvent = event
      @confirmPaymentIntent()

  setError: (message) ->
    $(@errorTarget).html(message).show()
