import ModalController from './modal_controller'

export default class extends ModalController
  @targets = ['modalTrigger']

  open: (event) ->
    target = $(event.target)
    id = target.closest('.customer-coverage').attr('data-id')
    $('.modal-root').attr('data-active', true)
    $(".coverage-terms-modal[data-id=#{id}]").attr('data-active', true)

  close: (event) ->
    target.closest("[data-action='terms-modal#open']").length
    super(event, false)
