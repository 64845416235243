import Cleave from 'cleave.js'
import { Controller } from 'stimulus'

export default class extends Controller
  @targets = ['number', 'phone', 'zipcode']

  connect: ->
    for input in @numberTargets
      new Cleave(input, numeral: true, delimiter: '')

    for input in @zipcodeTargets
      new Cleave(input,
        numericOnly: true,
        blocks: [5]
      )

    for input in @phoneTargets
      new Cleave(input,
        numericOnly: true
        blocks: [0, 3, 0, 3, 4]
        delimiters: ['(', ')', ' ', '-']
      )
