import { Controller } from 'stimulus'

export default class extends Controller
  @targets = ['yearMakeModel', 'year', 'make', 'model', 'postalCode', 'button']

  initialize: ->
    $(@yearMakeModelTarget).autocomplete
      serviceUrl: '/autocomplete'
      autoSelectFirst: true
      onSelect: (suggestion) =>
        @yearTarget.value = suggestion.data.year
        @makeTarget.value = suggestion.data.make
        @modelTarget.value = suggestion.data.model
        @update()

        @data.set('validVehicle', true)

      onInvalidateSelection: =>
        @data.set('validVehicle', false)

    $(document).one 'turbolinks:before-cache', =>
      $.rails.enableElement(@buttonTarget)

  submit: (event) ->
    @submitting = true

  update: ->
    # form is submitting
    return if @submitting

    if @yearTarget.value && @makeTarget.value && @modelTarget.value &&
    (!@hasPostalCodeTarget || @postalCodeTarget.value.length == 5)
      $(@buttonTarget).prop('disabled', false)
    else
      $(@buttonTarget).prop('disabled', true)

  change: (event) ->
    @clearVehicle() unless @data.get('validVehicle') == 'true'

  clearVehicle: =>
    @data.set('validVehicle', false)
    fields = [@yearMakeModelTarget, @yearTarget, @makeTarget, @modelTarget]
    for field in fields
      field.value = ''
