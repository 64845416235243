import Compressor from 'compressorjs'
import S3Uploader from 'src/javascript/s3_uploader'
require 'text-image'
import { Controller } from 'stimulus'
import WebFont from 'webfontloader'

export default class extends Controller
  @targets = ['name', 'signatureDisplay', 'signature']

  connect: ->
    WebFont.load(
      google: {
        families: ['Alex Brush']
      }
      fontactive: =>
        @generateSignature()
    )

    style =
      font: 'Alex Brush'
      color: 'white'
      size: 50 # boost the size so it looks sharp when scaled down
      strokeColor: 'rgba(0, 0, 0, 0)'
      lineHeight: 2
    @signatureImage = TextImage(style)

  uploadImage: (event) ->
    input = event.target
    url = $(input).data('url')
    uploader = new S3Uploader(input)
    containerEl = $(input).closest('.image-upload-root')
    progressEl = containerEl.find('.progress')
    imageEl = containerEl.find('.image')

    new Compressor input.files[0],
      quality: 0.95
      maxWidth: 4096
      maxHeight: Infinity
      success: (result) ->
        uploader.post(
          result,
          name: result.name,
          success: (response) ->
            containerEl.attr('data-state', 'ready')
            imageEl.css('backgroundImage', "url(#{response.url})")
            containerEl.attr('data-has-image', true)
            progressEl.css('transform', 'translateX(-100%)')

            $.ajax
              type: 'put'
              url: url
              data:
                image: response.url
              success: ->
                input.value = ''
              error: ->
                # unset the image so the user is forced to attempt reuploading
                containerEl.attr('data-has-image', false)
                imageEl.css('backgroundImage', 'none')

          progress: (progress) ->
            containerEl.attr('data-state', 'uploading')
            progressEl.css('transform', "translateX(#{(progress * 100) - 100}%)")
          error: (response) ->
            containerEl.attr('data-state', 'ready')
        )
      error: (err) ->
        input.value = ''

  deleteImage: (event) ->
    event.preventDefault()
    containerEl = $(event.target).closest('.image-upload-root')
    imageEl = containerEl.find('.image')
    url = $(event.target).data('url')
    $.ajax
      type: 'put'
      url: url
      data:
        image: ''
      success: ->
        containerEl.attr('data-has-image', false)
        imageEl.css('backgroundImage', 'none')

  generateSignature: ->
    text = "  #{@nameTarget.value}  " # pad with spacing to prevent clipping
    signatureImageString = @signatureImage.toDataURL(text)
    @signatureDisplayTarget.src = signatureImageString
    @signatureTarget.value = signatureImageString
