import ModalController from './modal_controller'

export default class extends ModalController
  @targets = ['modalTrigger']

  open: (event) ->
    if !$(event.target).closest('.view-coverage-terms-button').length
      target = $(event.target)
      id = target.closest('.coverage').attr('data-id')
      cm = new CoverageModal(id)
      cm.open()

  showConfiguration: (event) ->
    target = $(event.target)
    cm = new CoverageModal(target.closest('.coverage-modal').data('id'))
    cm.showConfiguration()

  close: (event) ->
    if event.type == 'click'
      return if $(event.target).closest('.coverage')[0] in @modalTriggerTargets

    callback = false

    if $('.coverage-modal[data-active="true"]').length
      callback = ->
        cm = new CoverageModal($('.coverage-modal[data-active=true]').data('id'))
        cm.close()

    super(event, callback)
