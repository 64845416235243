import Utilities from './utilities'
class S3Uploader

  constructor: (el) ->
    @el = $(el)
    @url = @el.data('presign-url')

    s3Fields = [
      'xAmzSignature', 'xAmzDate', 'xAmzAlgorithm', 'xAmzCredential',
      'policy', 'contentType', 'successActionStatus', 'acl', 'key',
      'cacheControl', 'expires'
    ]
    @formData = new FormData()
    for field in s3Fields
      fieldName =
        if field == 'successActionStatus'
          'success_action_status'
        else
          Utilities.camelToDash(field)
      @formData.append(fieldName, @el.data(field))


  post: (blob, options = {}) ->
    hash = Math.random().toString(36).substring(5)
    options.name ||= 'blob'
    @formData.append('file', blob, "#{hash}-#{options.name}")
    $.ajax
      type: 'post'
      url: @url
      contentType: false
      enctype: 'multipart/form-data'
      dataType: 'xml'
      processData: false
      data: @formData
      xhr: ->
        xhr = new (window.XMLHttpRequest)

        xhr.upload.addEventListener 'progress', ((evt) ->
          if evt.lengthComputable
            percentComplete = evt.loaded / evt.total
            options.progress(percentComplete) if options.progress
        ), false

        xhr
      success: (event, status) =>
        response = @buildResponse(event)
        # @el.trigger('s3_upload_complete', response)
        options.success(response) if options.success

      error: (event, status) =>
        error = event.responseXML.documentElement.querySelector('Error Message').textContent
        # @el.trigger('s3_upload_error', error)
        options.error(error) if options.error


  buildResponse: (response) ->
    url = new URL(response.querySelector('PostResponse Location').textContent)
    { url: url.href, filename: url.pathname }

export default S3Uploader
