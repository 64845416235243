# This file is automatically compiled by Webpack, along with any other files
# present in this directory. You're encouraged to place your actual application logic in
# a relevant structure within app/javascript and only use these pack files to reference
# that code so it'll be compiled.

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('devbridge-autocomplete')
import '../src/javascript/init'

# Uncomment to copy all static images under ../images to the output folder and reference
# them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
# or the `imagePath` JavaScript helper below.
images = require.context('../images', true)
pdfs = require.context('../pdfs', true)
zips = require.context('../zips', true)
import '../src/stylesheets/application.scss'

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
application = Application.start()
context = require.context('controllers', true, /.coffee$/)
application.load(definitionsFromContext(context))
