class CoverageModal

  constructor: (id) ->
    @modalRoot = $('.modal-root')
    @modal = $(".coverage-modal[data-id=#{id}]")
    @modalViews = @modal.find('.coverage-modal-views')
    @coverageInfo = @modal.find('.coverage-modal-info')
    @coverageAction = @modal.find('.coverage-modal-header-action')
    @iframe = @modal.find('iframe')

  close: (callback) ->
    @modalRoot.attr('data-active', false)
    @modal.attr('data-active', false)

    @modal.addClass('no-transition')
    @modalViews.addClass('no-transition')
    @modal.attr('data-state', 'info')
    @coverageInfo.stop().css('height', '')
    # this forces the stuff above to happen before the transition is re-added
    @modalViews[0].offsetHeight
    @modal.removeClass('no-transition')
    @modalViews.removeClass('no-transition')

    @coverageAction.show()
    @iframe.attr('src', @iframe.attr('src'))

    callback() if callback != undefined

  open: ->
    videoId = @modal.find('.coverage-modal-info').attr('data-video-id')
    @iframe.attr('src', "https://www.youtube.com/embed/#{videoId}") unless @iframe.attr('src')
    @iframe.on 'load', =>
      @iframe.closest('.skeleton-container').attr('data-loading', false)

    @modalRoot.attr('data-active', true)
    @modal.attr('data-active', true)

  showConfiguration: ->
    modalMainHeight = @modal.outerHeight() - @modal.find('.coverage-modal-header').outerHeight()
    @coverageInfo.css('height', modalMainHeight).animate(
      height: 0
    , 350)
    @modal.attr('data-state', 'form')
    @coverageAction.hide()

  updateHeaderAction: (html) ->
    @modal.find('.coverage-modal-header-action').html(html)

export default CoverageModal
