import Utilities from 'src/javascript/utilities'
import { Controller } from 'stimulus'

export default class extends Controller
  @targets = ['coverageType', 'coverageOptions', 'qualifier',
    'unqualifiedMessage', 'term', 'coatingBrand', 'coatingPackageSelect']

  connect: ->
    if @hasQualifierTarget
      $(@unqualifiedMessageTarget).hide()
      $(@coverageOptionsTarget).hide()

    $(@termTargets).last().prop('checked', true)

    code = @data.get('code')
    if ['exterior', 'ppf', 'glass'].includes(code)
      if code == 'glass' && @coverageTypeTarget.options.length == 2
        @coverageTypeTarget.value = @coverageTypeTarget.options[1].value
      else
        $(@termTargets).parent('label').hide()

    if @hasCoatingPackageSelectTarget
      @coatingPackageOptions = $(@coatingPackageSelectTargets).find('option')
      $(@coatingPackageSelectTarget).find('option[data-brand]').remove()

  disconnect: ->
    if @hasCoatingPackageSelectTarget
      @coatingPackageOptions.each (i, el) =>
        @coatingPackageSelectTarget.appendChild(el)

  hasQualifier: (event) ->
    if event.target.value == 'Yes'
      $(@unqualifiedMessageTarget).slideUp()
      $(@coverageOptionsTarget).slideDown()
    else if event.target.value == 'No'
      $(@coverageOptionsTarget).slideUp()
      $(@unqualifiedMessageTarget).slideDown()
    else
      $(@coverageOptionsTarget).slideUp()
      $(@unqualifiedMessageTarget).slideUp()

  additionalSelected: (event) ->
    if event.target == @coverageTypeTarget
      group = event.target.value
      $(@termTargets).parent('label').hide()
      $(@termTargets).filter("[data-group='#{group}']").parent('label').show()
      $(@termTargets).filter(':visible').last().prop('checked', true)

  selectCoatingBrand: (event) ->
    brand = event.target.value
    $(@coatingPackageSelectTarget).find('option[data-brand]').remove()
    @coatingPackageOptions.each (i, el) =>
      @coatingPackageSelectTarget.appendChild(el) if $(el).data('brand') == brand

  selectCoatingPackage: (event) ->
    value = event.target.value
    [coatingBrand, coatingPackage, name, term] = value.split('|')

    if name and term
      $(@termTargets).each ->
        if @value == term
          $(@).parent('label').show()
        else
          $(@).parent('label').hide()
      $(@termTargets).filter(':visible').last().prop('checked', true)

      @coatingBrandTarget.value = name
