import { Controller } from 'stimulus'

export default class extends Controller
  @targets = ['form']

  connect: ->
    @element.addEventListener 'invalid', ((event) =>
      event.preventDefault()

      return if $(@element).find('.field_with_errors').length
      @setError(event.target)
    ), true
    @inputs = $(@element).find('input, select, textarea')

    @inputs.on 'change blur', (event) =>
      setTimeout =>
        if event.target.checkValidity()
          @clearError(event.target)
        else
          @setError(event.target)
      , 200

    @inputs.on 'keyup', (event) =>
      setTimeout =>
        @clearError(event.target) if event.target.validity.valid
      , 200

  disconnect: ->
    @element.removeEventListener('invalid', {})
    @inputs.off('change blur keyup')

  setError: (target) ->
    @clearError(target)

    wrapper = $(target).closest('.input')
    wrapper.addClass 'field_with_errors'
    message =
      if target.validity.valueMissing
        "can't be blank"
      else
        target.validationMessage
    error = $('<span></span>').html(message)
    error.addClass 'error'
    wrapper.append(error)

  clearError: (target) ->
    wrapper = $(target).closest('.input')
    wrapper.removeClass 'field_with_errors'
    wrapper.find('span.error').remove()
