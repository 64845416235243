CartSummary =
  opened: ->
    $('.cart-summary-root').attr('data-expanded') == 'true'

  open: ->
    $('.cart-summary-root').attr('data-expanded', true)
    @resize()

  close: ->
    $('.cart-summary-root').attr('data-expanded', false)
    $('.cart-summary').css('transform', 'none')

  resize: (transition = true) ->
    if !transition
      oldTransition = $('.cart-summary').css('transition')

      $('.cart-summary').css('transition', '0s')
      setTimeout ->
        $('.cart-summary').css('transition', oldTransition)
      , 100

    if @opened()
      breakdownH = $('.breakdown').outerHeight()
      $('.cart-summary').css('transform', "translateY(#{(breakdownH * -1)}px)")

  toggle: ->
    if @opened() then @close() else @open()

export default CartSummary
